<h1 mat-dialog-title>Error</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button
    class="ml-auto"
    mat-button
    [mat-dialog-close]="true"
  >
    OK
  </button>
</div>
